import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Velocity Street Rentals
			</title>
			<meta name={"description"} content={"Відчуйте гострі відчуття з кожною милею"} />
			<meta property={"og:title"} content={"Velocity Street Rentals"} />
			<meta property={"og:description"} content={"Відчуйте гострі відчуття з кожною милею"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link2" />
			<Override slot="box1" />
		</Components.Header>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-16">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					padding="0px 0px 50% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						src="https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11:27:28.697Z"
						position="absolute"
						width="100%"
						bottom="0px"
						right={0}
						min-height="100%"
						object-fit="cover"
						display="block"
						top="auto"
						left={0}
						srcSet="https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				justify-content="space-between"
				width="58%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				lg-justify-content="center"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box display="flex" flex-direction="column" md-width="100%">
					<Text
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						md-text-align="left"
					>
						Ласкаво просимо до Velocity Street Rentals
					</Text>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
				width="40%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="space-between"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-padding="0px 16px 16px 16px"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						Вирушайте в подорож, де адреналін поєднується з вишуканістю в Velocity Street Rentals, вашому головному джерелі сучасних масл-карів. Наш автопарк створений для тих, хто жадає азарту та незабутнього досвіду водіння. Незалежно від того, чи плануєте ви мальовничу подорож чи бажаєте незабутньої ночі, наші м’язові автомобілі обіцяють зробити кожну мить хвилюючою.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-2.jpg?v=2024-06-14T11:27:28.704Z) center/cover repeat scroll padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="900px"
					background="rgba(255, 255, 255, 0.9)"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
					border-radius="30px"
					margin="0px auto 0px auto"
				>
					<Text margin="0px 0px 20px 0px" font="--headline3" text-align="center">
						Velocity Street Rentals — це не просто оренда автомобілів, це створення стилю життя.
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
						Кожен масл-кар у нашій колекції поєднує сильну потужність із витонченою естетикою, що гарантує, що ваша оренда буде просто вражаючою. Від реву двигунів до гладкої полірованої обробки, наші автомобілі є свідченням автомобільно
					</Text>
					<Button
						background="--color-darkL1"
						padding="12px 30px 12px 30px"
						border-radius="25px"
						href="/menu"
						type="link"
						text-decoration-line="initial"
					>
						Послуги
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				align-self="center"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Чому Velocity?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					У Velocity Street Rentals ми виходимо за рамки основ, щоб забезпечити винятковий досвід оренди з кількома ключовими перевагами:{"\n"}
					<br />
					Чудовий стан: Кожен транспортний засіб ретельно обслуговується та деталізується, пропонуючи вам бездоганний стан авто в автосалоні з відчуттям гоночної траси.{"\n"}
					<br />
					Індивідуальні пакети: Ми пропонуємо індивідуальні пакети оренди, які задовольняють ваші конкретні потреби, чи то на день, вихідні чи особливу подію.
					<br />
					{"\n"}Порада експерта: Наш досвідчений персонал сам є ентузіастом і може надати детальну інформацію про кожну модель, допомагаючи вам вибрати ідеальний автомобіль для вашої пригоди.{"\n"}
					<br />
					Стратегічне розташування: Легко доступне, наше розташування дозволяє швидко отримати та повернути, що робить вашу оренду легкою та зручною.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11:27:28.718Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11%3A27%3A28.718Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11%3A27%3A28.718Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11%3A27%3A28.718Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11%3A27%3A28.718Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11%3A27%3A28.718Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11%3A27%3A28.718Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-4.jpg?v=2024-06-14T11%3A27%3A28.718Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});